@import "~bootstrap/dist/css/bootstrap.css";
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";

.theme-green .bs-datepicker-head {
    background-color: #938489 !important;
}

.theme-green .bs-datepicker-body table td.week span {
    color: #938489;
}

.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
    background-color: #938489;
}

.font-color-primary {
    color: #938489;
}

.font-color-secundary {
    color: #ffffff;
}

.font-color-tertiary {
    color: #D0E2EA;
}

.click:hover {
    cursor: pointer;
}

.txt-start {
    text-align: start;
}

.txt-center {
    text-align: center;
}

.txt-end {
    text-align: end;
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('assets/fonts/Inter-Bold.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Italic';
    src: url('assets/fonts/Inter-Italic.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Light';
    src: url('assets/fonts/Inter-Light.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('assets/fonts/Inter-Medium.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('assets/fonts/Inter-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Thin';
    src: url('assets/fonts/Inter-Thin.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'times_sans_serif';
    src: url('assets/fonts/times_sans_serif.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

h1 {
    font-family: "times_sans_serif";
}

#protegON-btnMinOpenSettings svg {
    width: 25px;
}